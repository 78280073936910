import { PropsWithChildren } from "react";

import Image from "next/image";
import { twMerge } from "tailwind-merge";

import CompanyLogo from "components/CompanyLogo";
import { LayoutType } from "components/DeviceSet";
import DeviceSet from "components/DeviceSet";
import Icon from "components/Icon";
import TransitionLink from "components/TransitionLink";

import { COMPANIES } from "data/companies";

interface HiddenProjectProps {
  slug: string;
  className?: string;
  link?: boolean;
}

const HiddenProject = ({ slug, className: customClassName, link }: HiddenProjectProps) => {
  // Background color
  const { project = {}, name: companyName } = COMPANIES[slug] || {};

  // classes to be applied to a wrapper div around the
  // DeviceSet, based on which layout is used
  const deviceSetWrapperClasses: Record<LayoutType, string> = {
    default: "flex h-full w-full items-center justify-center",
    web: "h-full w-full ml-[8%] mt-[10%]",
  };

  const deviceSetClasses: Record<LayoutType, string> = {
    default: "first:group-hover/project:translate-y-10 last:group-hover/project:-translate-y-10",
    web: "first:group-hover/project:translate-x-0 last:group-hover/project:translate-x-24",
  };

  function Wrapper({
    className,
    children,
  }: PropsWithChildren<{
    className: string;
  }>) {
    // Link to case study
    if (link) {
      return (
        <TransitionLink
          className={twMerge(className, customClassName)}
          href={`/projects/${slug}`}
          ariaLabel={`view the ${companyName} case study`}
        >
          {children}
        </TransitionLink>
      );
    }
    // No case study to link to
    else {
      return <div className={twMerge(className, customClassName)}>{children}</div>;
    }
  }

  return (
    <Wrapper className="group/project project relative flex h-full flex-col overflow-hidden transition-all hover:text-light hover:brightness-105">
      {/* Header */}
      <div className="relative z-10 mt-[8%] flex items-center justify-between px-[10%]">
        <div className="max-w-[45%]">
          <CompanyLogo slug={slug} className="h-10 w-full" />
        </div>
        {link && <Icon slug="right-arrow" className="arrow-icon h-4 w-4" />}
      </div>
      {/* Graphic layer (optional) */}
      {project.graphic && (
        <div className="graphic absolute h-full w-full">
          <Image
            src={
              process.env.NEXT_PUBLIC_ASSET_PREFIX
                ? process.env.NEXT_PUBLIC_ASSET_PREFIX + project.graphic?.src
                : project.graphic?.src
            }
            width="512"
            height="512"
            alt=""
            className="h-full w-full object-cover"
          />
        </div>
      )}
      {/* Devices / Screens */}
      {project.deviceSet && (
        <div className={deviceSetWrapperClasses[project.deviceSet.layout || "default"]}>
          <DeviceSet
            screens={project.deviceSet.screens}
            overlapDevices={project.deviceSet.overlapDevices}
            layout={project.deviceSet.layout || "default"}
            classNames={deviceSetClasses[project.deviceSet.layout || "default"]}
          />
        </div>
      )}
      {/* Standalone Image */}
      {/* Used by: warby-parker */}
      {project.image && (
        <div className="relative flex h-full w-full flex-1 items-center justify-center transition-all group-hover/project:scale-105">
          <Image
            src={project.image.src}
            width="512"
            height="512"
            alt=""
            className="absolute h-full w-full object-contain"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default HiddenProject;
