import { DeviceSetType } from "components/DeviceSet";

import { COMPANIES, projectTypeMap } from "data/companies";

import { isDarkMode } from "utilities/color";

import Hidden from "./mode-hidden";
import Reveal from "./mode-reveal";
import Static from "./mode-static";

// Refers to the description behavior
export type TileType = "reveal" | "static" | "hidden";

export type DescriptionType = {
  intro?: string;
  copy: Array<string>;
};

export type GraphicType = {
  src: string;
  parallax?: "small" | "large";
  onTop?: boolean;
};

// Default project information object
// Used in: companies.ts
export type projectType = {
  types?: Array<keyof typeof projectTypeMap>;
  description?: DescriptionType;
  deviceSet?: DeviceSetType;
  graphic?: GraphicType;
  gradientBG?: boolean;
  image?: { src: string };
};

export interface ProjectProps {
  slug: string;
  className?: string;
  description?: DescriptionType;
  link?: boolean;
  tileType: TileType;
}

const Project = ({ slug, description, link, tileType }: ProjectProps) => {
  const company = COMPANIES[slug];

  // Custom gradient - used for California DMV
  const customGradientOverride = company.color?.customGradientOverride || null;

  // Background color with support for optional gradients
  let bgColor = "#EEE";
  if (company.color) {
    // If there's a gradient background
    if (company.project?.gradientBG) {
      // if there is a custom gradient override - used on California DMV
      if (customGradientOverride) {
        bgColor = `linear-gradient(${customGradientOverride})`;
      } else {
        bgColor = `linear-gradient(${company.color?.primary},${company.color?.gradient})`;
      }
    }

    // Otherwise return just the primary
    else bgColor = company.color?.primary;
  }

  //
  const project = company.project || {};

  let tempDescription: DescriptionType = { copy: [] };
  if (tileType !== "hidden") {
    // Passed as a prop...
    if (description) {
      tempDescription = {
        intro: description.intro,
        copy: description.copy,
      };
    }
    // ...or from the default companies data Record
    else if (project && project.description) {
      tempDescription = {
        intro: project.description.intro,
        copy: project.description.copy,
      };
    }
  }

  return (
    <div
      className="overflow-hidden project"
      style={{
        background: bgColor,
        color: isDarkMode(company.color?.primary || "#FFF") ? "#FFF" : "#000",
      }}
    >
      {tileType === "hidden" && <Hidden slug={slug} link={link} />}
      {tileType === "reveal" && <Reveal slug={slug} description={tempDescription} />}
      {tileType === "static" && <Static slug={slug} description={tempDescription} />}
    </div>
  );
};

export default Project;
