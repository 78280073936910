import { twJoin, twMerge } from "tailwind-merge";

import ProjectCard, { TileType } from "components/ProjectCard";

interface ProjectGridProps {
  projects: Array<{ slug: string; tileType?: TileType; link?: boolean }>;
  size?: string;
  className?: string;
  // Optional general mode to override the predefined description types,
  // if individual description types are not defined
  // (Used on the career hub "featured projects" section)
  tileType?: TileType;
  customSizing?: Boolean;
}

const ProjectGrid = ({ projects, className, tileType, customSizing }: ProjectGridProps) => {
  return (
    <div
      className={twMerge(
        "z-1 grid w-full",
        className,
        !customSizing &&
          "auto-rows-[150vw] md:auto-rows-[66vw] md:grid-cols-2 lg:auto-rows-[45vw] lg:grid-cols-3"
      )}
    >
      {projects.map((project) => (
        <ProjectCard
          // If an odd number of projects are given, on 2 col layouts hide last project
          className={twJoin(projects.length % 2 === 1 && "md:last:hidden lg:last:block")}
          key={project.slug}
          slug={project.slug}
          tileType={project.tileType || tileType || "hidden"}
          link={project.link || false}
        />
      ))}
    </div>
  );
};

export default ProjectGrid;
