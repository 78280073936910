// Returns whether given background color should have a dark mode color pallette
export function isDarkMode(hexcolor) {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 150;
}

// Returns a tailwind class that's a random shade of Fueled's brand red (#AE0000)
export function randomRedBG() {
  const shades = [300, 500, 700];

  return `bg-brand-${shades[Math.floor(Math.random() * shades.length)]}`;
}
