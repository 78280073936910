"use strict";

// General device information
// Used in: Device

export type DeviceModelType = "iphone-5" | "iphone-x" | "iphone-12" | "macbook-air";
export type OrientationType = "screen" | "front";

type DeviceDataType = {
  // height:width ratio
  // [height, width]
  screenRatio: [number, number];
};

export const DEVICES: Record<string, Record<string, DeviceDataType>> = {
  iphone: {
    "5": { screenRatio: [16, 9] },
    x: { screenRatio: [13, 6] },
    "12": { screenRatio: [19.5, 9] },
  },
  pixel: {
    "5": {
      screenRatio: [19.5, 9],
    },
  },
  macbook: {
    air: {
      screenRatio: [10, 16],
    },
  },
};
