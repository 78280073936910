import Image from "next/image";
import { twJoin } from "tailwind-merge";

import { DEVICES, DeviceModelType, OrientationType } from "data/devices";

import styles from "./Device.module.scss";

export type DeviceType = {
  // Rounded corners, drop shadow
  minimal?: boolean;
  src: string;
  orientation: OrientationType;
  deviceModel: DeviceModelType;
};

const Device = ({ minimal = true, src, deviceModel }: DeviceType) => {
  // Retrieve the ratio for this device + model from DEVICES
  let [device, model] = deviceModel.split("-");
  let ratio = DEVICES[device][model].screenRatio;
  // Assign height and width vars to the style - used in _device.scss
  const style = { "--device-height": ratio[0], "--device-width": ratio[1] } as React.CSSProperties;

  if (process.env.NEXT_PUBLIC_ASSET_PREFIX) {
    src = process.env.NEXT_PUBLIC_ASSET_PREFIX + src;
  }

  return (
    <div
      className={twJoin(
        styles["fdc-device"],
        "relative",
        minimal && "rounded-md md:rounded-3xl shadow-xl"
      )}
      device-width={ratio[0]}
      device-height={ratio[1]}
      style={style}
    >
      <Image
        src={src}
        alt=""
        fill
        // Yes you need the shadow in both places
        className={twJoin(minimal && "object-cover rounded-md shadow-lg md:rounded-2xl")}
      />
    </div>
  );
};

export default Device;
