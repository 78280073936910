import { twMerge } from "tailwind-merge";

import { DeviceType } from "components/Device";
import Device from "components/Device";

export type LayoutType = "default" | "web";
export type DeviceSetType = {
  screens: Array<DeviceType>;
  overlapDevices: boolean;
  layout?: LayoutType;
  classNames?: string;
};

const deviceSetClasses: Record<LayoutType, string> = {
  default: "flex h-full w-full items-center justify-center mt-6",
  web: "h-full w-full ml-[8%]",
};

const wrapperClasses: Record<LayoutType, string> = {
  default: "w-[42%] first:translate-y-6 last:-translate-y-6 ",
  web: "w-[100%] first:translate-x-24",
};

const DeviceSet = ({ screens, overlapDevices, layout = "default", classNames }: DeviceSetType) => {
  return (
    <div className={deviceSetClasses[layout]}>
      {screens.map((screen, i) => (
        <div
          key={i}
          className={twMerge(
            "transition-transform",
            wrapperClasses[layout],
            overlapDevices && layout === "default" && "last:-ml-[5%]",
            overlapDevices && layout === "web" && "last:-mt-[40%]",
            classNames
          )}
        >
          <Device
            src={screen.src}
            orientation="screen"
            deviceModel={screen.deviceModel}
            minimal={screen.minimal}
          />
        </div>
      ))}
    </div>
  );
};

export default DeviceSet;
