import Image from "next/image";

import CompanyLogo from "components/CompanyLogo";
import DeviceSet from "components/DeviceSet";

import { COMPANIES } from "data/companies";

import { isDarkMode } from "utilities/color";

import { DescriptionType } from ".";

// Wrapper for entire project tile
// (to differentiate linked projects from description ones)

export type StaticProjectProps = {
  slug: string;
  link?: boolean;
  description: DescriptionType;
  className?: string;
  // Required becuase there might be instances where there is a defined
  // description in COMPANIES but we don't want to show it
};

const StaticProject = ({ slug, description, className }: StaticProjectProps) => {
  // Background color
  const BGColor = COMPANIES[slug].color?.primary || "#EEE";
  const project = COMPANIES[slug].project || {};

  return (
    <div className={`${className} project-tile-${slug}`}>
      {/* Main Tile */}
      <div
        className="group/project flex h-full flex-col overflow-hidden"
        style={{ backgroundColor: BGColor, color: isDarkMode(BGColor) ? "#FFF" : "#000" }}
      >
        {/* Header */}
        <div className="flex items-center justify-between mt-[8%] px-[10%]">
          <div className="max-w-[45%]">
            <CompanyLogo slug={slug} className="h-10 w-full" />
          </div>
        </div>
        {/* Graphic layer (optional) */}
        <div className="graphic absolute"></div>
        {/* Devices / Screens */}
        {project.deviceSet && (
          <div className="mt-[10%]">
            <DeviceSet
              screens={project.deviceSet.screens}
              overlapDevices={project.deviceSet.overlapDevices}
              layout={project.deviceSet.layout || "default"}
            />
          </div>
        )}
        {/* Standalone Image */}
        {/* Used by: warby-parker */}
        {project.image && (
          <div className="relative flex h-full w-full flex-1 items-center justify-center transition-all group-hover/project:scale-105">
            <Image
              src={project.image.src}
              width="512"
              height="512"
              alt=""
              className="absolute h-full w-full object-contain"
            />
          </div>
        )}
      </div>
      {/* static Description Drawer */}
      <div className="pointer-events-none absolute mt-32 translate-y-8 px-12 opacity-0 transition-all group-hover/project:translate-y-0 group-hover/project:opacity-30">
        <div>
          <h4 className="h3 font-normal">{COMPANIES[slug].name}</h4>
          <p className="body-lg">{description.intro}</p>
          <p>{description.copy}</p>
        </div>
      </div>
    </div>
  );
};

export default StaticProject;
