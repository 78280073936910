import { useState } from "react";

import Image from "next/image";
import { twJoin, twMerge } from "tailwind-merge";

import CompanyLogo from "components/CompanyLogo";
import { LayoutType } from "components/DeviceSet";
import DeviceSet from "components/DeviceSet";
import Icon from "components/Icon";

import { COMPANIES } from "data/companies";

import { DescriptionType } from ".";

interface RevealProjectProps {
  slug: string;
  description: DescriptionType;
  className?: string;
}

const RevealProject = ({ slug, description, className }: RevealProjectProps) => {
  // Background color
  const company = COMPANIES[slug] || {};
  const project = company.project || {};

  // Optional max width override for logo, fallback to 45
  // used for Whtie House Logo
  const maxLogoWidth = company?.display?.logoMaxWidthPercent || 45;

  // Optional asset to replace arrow
  // used for DMV
  const arrowOverride = company?.display?.arrowOverride || null;

  // Optional overlay for background image
  // used for DMV
  const backgroundOverlayColor = company?.display?.overlay?.color || null;
  const backgroundOverlayOpacity = company?.display?.overlay?.opacity || 0.4;

  const deviceSetClasses: Record<LayoutType, string> = {
    default:
      "first:group-hover/project:translate-y-20 last:group-hover/project:translate-y-5 duration-[250ms]",
    web: "first:group-hover/project:translate-x-0 last:group-hover/project:translate-x-24",
  };

  // Toggle for reveal descriptions
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  function handleClick() {
    setDescriptionOpen((v) => !v);
  }

  return (
    <div
      className={twMerge(
        "project-reveal group/project relative flex h-full cursor-pointer flex-col overflow-hidden transition-all hover:brightness-105",
        className,
        descriptionOpen && "project-reveal--open"
      )}
      onClick={handleClick}
    >
      {/* Header */}
      <div className="relative z-10 mt-[8%] flex items-center justify-between px-[10%]">
        <div className={`max-w-[${maxLogoWidth}%]`}>
          <CompanyLogo slug={slug} className="w-full h-10" />
        </div>

        {arrowOverride ? (
          <div className="flex items-center justify-end w-4 h-4 overflow-visible">
            <Image
              src={arrowOverride.src}
              alt={arrowOverride.alt}
              width={arrowOverride.width}
              height={arrowOverride.height}
              className="max-w-[none]"
            />
          </div>
        ) : (
          <Icon slug="down-arrow" className="w-4 h-4 arrow-icon" />
        )}
      </div>
      {/* Graphic layer (optional) */}
      {project.graphic && (
        <div
          className={twJoin(
            "graphic absolute z-0 h-full w-full",
            project.graphic.parallax && `graphic-parallax--${project.graphic.parallax}`,
            project.graphic.onTop && "z-10"
          )}
        >
          <Image
            src={
              process.env.NEXT_PUBLIC_ASSET_PREFIX
                ? process.env.NEXT_PUBLIC_ASSET_PREFIX + project.graphic?.src
                : project.graphic?.src
            }
            width="512"
            height="512"
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
      )}
      {/* Background Overlay (optional) */}
      {backgroundOverlayColor && backgroundOverlayOpacity && (
        <div
          className="absolute z-0 w-full h-full"
          style={{
            backgroundColor: backgroundOverlayColor,
            opacity: backgroundOverlayOpacity,
          }}
        ></div>
      )}
      {/* Revealing description drawer */}
      <div
        className={twJoin(
          "project-description pointer-events-none z-1 px-12 pt-12 transition-all duration-[250ms]",
          !descriptionOpen &&
            "translate-y-[20%] group-hover/project:translate-y-0 group-hover/project:opacity-30",
          descriptionOpen ? "opacity-100" : "opacity-0"
        )}
      >
        <div>
          {description.intro && <p className="body-lg">{description.intro}</p>}
          {description.copy.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
        </div>
      </div>
      {/* Devices / Screens */}
      {project.deviceSet && (
        <div className="z-[2] mt-[5%]">
          <DeviceSet
            screens={project.deviceSet.screens}
            overlapDevices={project.deviceSet.overlapDevices}
            layout={project.deviceSet.layout || "default"}
            classNames={deviceSetClasses[project.deviceSet.layout || "default"]}
          />
        </div>
      )}
      {/* Standalone Image */}
      {/* Used by: warby-parker */}
      {project.image && (
        <div className="relative flex items-center justify-center flex-1 w-full h-full transition-all group-hover/project:scale-105">
          <Image
            src={project.image.src}
            width="512"
            height="512"
            alt=""
            className="absolute object-contain w-full h-full"
          />
        </div>
      )}
    </div>
  );
};

export default RevealProject;
